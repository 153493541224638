import React from 'react';
import { motion } from 'framer-motion';

import { Layout } from '../components/Layout';
import { Seo } from '../components/Seo';

function NotFoundPage() {
  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <Layout page="404">
        <Seo title="404: Not found" />
        <h1>{`NOT FOUND`}</h1>
        <p>{`You just hit a route that doesn&#39;t exist... the sadness.`}</p>
      </Layout>
    </motion.div>
  );
}

export default NotFoundPage;
